import NextHead from "next/head";
import FAVICON from "public/favicon.png";

interface Props {
  title?: string;
  description?: string;
  favicon?: string;
  canonical?: string;
  jsonLd?: object;
  Title?: string;
  Description?: string;
  Favicon?: string;
  Canonical?: string;
  JsonLd?: object;
}

const Head = (props: Props) => {
  const title = props.title || props.Title;
  const description = props.description || props.Description;
  const canonical = props.canonical || props.Canonical || "";
  const jsonLd = props.jsonLd || props.JsonLd || "";

  return (
    <NextHead>
      <title>{title}</title>
      {description && <meta key="description" name="description" content={description} />}
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="theme-color" content="#041E42" />
      <link key="icon" rel="icon" href={FAVICON.src} />
      {canonical && <link key="canonical" rel="canonical" href={canonical} />}

      {jsonLd && (
        <script
          nonce={"f9959a0860a72cc3"}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
          key="jsonld"
        />
      )}
    </NextHead>
  );
};

export default Head;
